import { useEffect } from "react";
import { getProducts } from "../lib/product";
import { LayoutFive } from "../layouts";
import { ProductSliderTwelve } from "../components/ProductSlider";
import { Spinner } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserDetails,
  getUserDetailsTrigger,
} from "../redux/actions/userActions";

const Index = () => {
  const products = useSelector((state) => state.productData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserDetailsTrigger());
    dispatch(getUserDetails());
  }, []);

  if (!products.length) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: 9999,
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="orange.500"
          size="xl"
        />
      </div>
    );
  }

  return (
    <>
      <LayoutFive navPositionClass="justify-content-start">
        <ProductSliderTwelve
          title="Trending Products"
          //TODO Change back to popular once there is data
          // products={getProducts(products, "", "popular", 15)}
          products={getProducts(products, "", "", 15)}
        />
      </LayoutFive>
    </>
  );
};

export default Index;
